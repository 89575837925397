<!-- 会员卡 -->

<template>
  <div class="vip-container main-cnt">
    <div class="title">会员卡列表</div>
    <div class="content">
      <common-table ref="tableRef" tableHeight="calc(100vh - 320px)" :ischeck="false" :filters="filters"
        :ispaging="true" :apiName="VipApi.getMemberCardLists" :columns="tableColumns" @edit="openDialog"
        @typeChange="typeChange">
        <template #operate>
          <el-button type="primary" round @click="openDialog"
            v-if="authData.indexOf('m_xE3LBWSVAllShut7sSINvE0y') != -1">
            <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
            新增会员卡
          </el-button>
          <el-button type="primary" round @click="registerBtn"
            v-if="authData.indexOf('m_f2DTlNxyaKHJXOyg02MAtj29xkUG') != -1">快捷录入</el-button>
        </template>
        <template #registerBtn="{ row }">
          <el-button v-if="row.use_status == '1' && authData.indexOf('m_3KFRZ3uadbbQzshECOfU21IF') != -1"
            @click="registerBtn(row)">录入通行证</el-button>
        </template>
      </common-table>
    </div>

    <!-- 新增、编辑会员卡 -->
    <w-dialog ref="dialogRef" class="vip-dialog" :title="ruleForm.mcd_id ? '编辑会员卡' : '新增会员卡'" width="60%"
      btnWidth="140px" top="6vh" :confirmText="ruleForm.mcd_id ? '确认编辑' : '确认新增'" @wConfirm="handleSure">
      <div style="overflow-x: hidden;overflow-y: auto;padding-right: 10px;max-height: 500px;">
        <el-form class="add-form" ref="formRef" :model="ruleForm" :rules="rules" labelPosition="top">
          <el-row :gutter="10">
            <el-col :span="8">
              <el-form-item label="会员卡名称" prop="mcd_name">
                <el-input v-model="ruleForm.mcd_name" clearable placeholder="请输入会员卡名称"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="园区" prop="mcd_sid">
                <el-select v-model="ruleForm.mcd_sid" placeholder="请选择园区" clearable
                  :disabled="ruleForm.mcd_id ? true : false">
                  <el-option v-for="el in parkOptions" :key="el.s_id" :label="el.s_name" :value="el.s_id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="来源" prop="mcd_mcwid">
                <el-select v-model="ruleForm.mcd_mcwid" placeholder="请选择会员来源" clearable>
                  <el-option v-for="el in sourceOptions" :key="el.id" :label="el.name" :value="el.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="类型" prop="mcd_stt_id">
                <el-select v-model="ruleForm.mcd_stt_id" placeholder="请选择类型" clearable
                  :disabled="ruleForm.mcd_id ? true : false" @change="typesOfChange(1)">
                  <el-option v-for="el in typesOptions" :key="el.stt_id" :label="el.stt_name" :value="el.stt_id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="8" v-if="secondaryTypeOption.length > 0">
              <el-form-item label="二级类型" prop="mcd_stt_sid">
                <el-select :disabled="ruleForm.mcd_id ? true : false" v-model="ruleForm.mcd_stt_sid" clearable
                  placeholder="请选择二级类型">
                  <el-option :label="item.stt_name" :value="item.stt_id" v-for="item in secondaryTypeOption"
                    :key="item.stt_id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="会员卡日期" prop="sellDate">
                <el-date-picker v-model="ruleForm.sellDate" type="daterange" range-separator="至"
                  start-placeholder="开始时间" end-placeholder="结束时间" value-format="YYYY-MM-DD">
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="8" v-if="ruleForm.mcd_stt_id == '2'">
              <el-form-item label="会员卡可用次数" prop="mcd_count">
                <el-input v-model="ruleForm.mcd_count" type="number" placeholder="请输入会员卡可用次数"
                  oninput="if(value < 1) value = 1" clearable></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="当天可用次数" prop="mcd_day_count">
                <el-input v-model="ruleForm.mcd_day_count" type="number" placeholder="请输入当天可用次数"
                  oninput="if(value < 1) value = 1" clearable></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="儿童数量" prop="mcd_child_ticket_count">
                <el-input v-model="ruleForm.mcd_child_ticket_count" type="number" placeholder="请输入儿童数量"
                  oninput="if(value < 0) value = 0" clearable></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="成人数量" prop="mcd_audit_ticket_count">
                <el-input v-model="ruleForm.mcd_audit_ticket_count" type="number" placeholder="请输入成人数量"
                  oninput="if(value < 0) value = 0" clearable></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="会员信息" prop="memberInfo">
                <el-autocomplete popper-class="my-autocomplete" v-model="ruleForm.memberInfo"
                  :fetch-suggestions="querySearch" placeholder="请选择" suffix-icon="el-icon-search" ref="autocomplete">
                  <template #default="{ item }" v-if="isShowMemberDatas">
                    <div class="flex justify_between" @click.stop="handleSelect(item)">
                      <span class="ss_m_l">{{ item.m_mobile }}</span>
                      <div class="name">{{ item.m_realname }}</div>
                    </div>
                    <div v-if="item.default" @click.stop="">
                      <div style="text-align: center;">{{ item.default }}</div>
                      <el-button class="cancle" type="primary" @click.stop="onRegisterBtn" plain round
                        style="width: 100%;">注册会员</el-button>
                    </div>
                  </template>
                </el-autocomplete>
                <div class="flex justify_between person_content" v-if="allPerson.m_mobile">
                  <span>{{ allPerson.m_mobile }}</span>
                  <span v-if="allPerson.m_realname">{{ allPerson.m_realname }}</span>
                </div>
              </el-form-item>
            </el-col>

            <el-col :span="8" v-if="ruleForm.mcd_mcwid == '5'">
              <el-form-item label="优惠券" prop="mcdSearch">
                <el-autocomplete popper-class="my-autocomplete" v-model="ruleForm.mcdSearch"
                  :fetch-suggestions="couponSearch" placeholder="请选择" suffix-icon="el-icon-search" ref="couponRefs">
                  <template #default="{ item }">
                    <div class="flex justify_between" @click.stop="couponSelect(item)">
                      <span class="ss_m_l">{{ item.m_mobile }}</span>
                    </div>
                  </template>
                </el-autocomplete>
                <div class="flex justify_between person_content" v-if="allCoupon.id">
                  <span>{{ allCoupon }}</span>
                </div>
              </el-form-item>
            </el-col>
          </el-row>

          <div class="upload-box" v-if="showUpload">
            <el-dropdown>
              <div class="into-face">录入人脸</div>
              <template #dropdown>
                <el-dropdown-menu class="face-dropdown-menu">
                  <el-dropdown-item class="face-dropdown-item">
                    <imgUpload uploadTitle="人脸照片" uploadText="本地上传" @uploadFile="uploadFile($event)"
                      :noReplaceImg="false" @uploadLoading="uploadLoading"></imgUpload>
                  </el-dropdown-item>
                  <el-dropdown-item @click="openFaceDialog(index)" class="face-dropdown-item">拍照录入
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <div class="img-container flex flex-wrap">
              <div class="face-img" v-if="facialPhotos.length > 0" v-for="(imgs, i) in facialPhotos" :key="i">
                <el-image style="width: 100px; height: 100px" :src="imgs.standard_url" :preview-src-list="srcList"
                  fit="cover">
                </el-image>
                <span class="del" @click="deleteFile(imgs, i)">
                  <el-icon>
                    <Close />
                  </el-icon>
                </span>
              </div>
            </div>
          </div>
          <el-form-item label="备注" prop="mcd_remark">
            <el-input v-model="ruleForm.mcd_remark" :rows="5" type="textarea" placeholder="请输入备注信息" />
          </el-form-item>
        </el-form>
      </div>
    </w-dialog>

    <w-dialog ref="ticketDialog" class="enter-dialog" title="录入信息" width="60%" btnWidth="140px" top="10vh"
      confirmText="确认录入" @wConfirm="confirm">
      <div class="ticket-content">
        <img-upload uploadTitle="" suggestText="" :fileList="fileList" :isShowUpload="false" :noReplaceImg="false">
        </img-upload>
        <div class="enter-title">录入日期：{{ dateDay }}</div>
        <div v-if="isQuick">
          <el-select v-model="s_id" placeholder="请选择园区" clearable>
            <el-option v-for="el in parkOptions" :key="el.s_id" :label="el.s_name" :value="el.s_id"></el-option>
          </el-select>
        </div>
        <div class="enter-title">儿童信息录入</div>
        <div class="visitor-item">
          <el-form label-position="right" class="visitor-form" @submit.native.prevent>
            <el-form-item prop="paper_sn" label="" v-for="(el, index) in childrenInfo" :key="index">
              <el-input v-model="el.paper_sn" autocomplete="off" placeholder="请用扫码枪识别手环码" :id="'children' + index"
                @keyup.enter.native="handleSn('children', index, index + 1)" clearable @blur="childrenBlur" />
            </el-form-item>
          </el-form>
        </div>
        <div class="add-btn" v-if="isQuick" @click="onAddLineBtn('children')">
          <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
        </div>

        <div class="enter-title">成人信息录入</div>
        <div class="visitor-item">
          <el-form label-position="right" class="visitor-form" @submit.native.prevent>
            <el-form-item prop="paper_sn" label="" v-for="(el, index) in adultInfo" :key="index">
              <el-input v-model="el.paper_sn" autocomplete="off" placeholder="请用扫码枪识别手环码" :id="'adult' + index"
                @keyup.enter.native="handleSn('adult', index, index + 1)" clearable @blur="childrenBlur" />
            </el-form-item>
          </el-form>
        </div>
        <div class="add-btn" v-if="isQuick" @click="onAddLineBtn('adult')">
          <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
        </div>
      </div>
    </w-dialog>

    <!-- 拍照上传 -->
    <FaceDialog ref="faceDialogRef" @submit="handleCamera"></FaceDialog>

    <EntryPass ref="entryPassRef" @determine="determine"></EntryPass>
  </div>
</template>

<script setup>
  import { ref, reactive, onMounted, computed, watch } from "vue";
  import { VipApi, BasicApi, ScenicApi } from "@/plugins/api.js";
  import { ElMessage } from "element-plus";
  import { checkStr, duplicates, } from "@/utils/common.js";
  import imgUpload from "@/components/img-upload/img-upload.vue";
  import { useStore } from "vuex";
  import FaceDialog from "../../scenic/components/FaceDialog.vue";
  import { Close } from "@element-plus/icons-vue";
  import dayjs from "dayjs";
  import EntryPass from "../../scenic/components/EntryPass.vue";

  const store = useStore();
  const authData = ref([]);  // 权限
  const menuTokens = computed(() => store.state.menuToken.menuTokens);
  const dateDay = ref(dayjs(new Date()).format("YYYY-MM-DD"));  // 当天日期
  const tableRef = ref(null);  // 表格对象
  const parkOptions = ref([]);  // 园区选项
  const sourceOptions = ref([]);  // 来源选项
  const typesOptions = ref([]);  // 类型选项
  const couponOptions = ref([]);  // 优惠券选项
  const dialogRef = ref(null);  // 新增、编辑对话框对象
  const formRef = ref(null);  // 表单对象
  const showUpload = ref(false);  // 是否显示上传
  const ticketDialog = ref(null);
  const mcd_id = ref(null);  // 录入会员卡id
  const childrenInfo = ref([]);  // 儿童信息
  const adultInfo = ref([]);  // 成人信息
  const fileList = ref([]);  // 人脸数据
  const ruleForm = ref({  // 表单内容
    mcd_name: '',  // 会员卡名称
    mcd_sid: '',  // 园区
    mcd_mcwid: '',  // 来源
    mcd_stt_id: '',  // 类型
    mcd_stt_sid: '',  // 二级类型
    sellDate: [], // 会员卡日期
    mcd_count: '',  // 会员卡可用次数
    mcd_day_count: '',  // 当天可用次数
    mcd_child_ticket_count: '',  // 儿童数量
    mcd_audit_ticket_count: '',  // 成人数量
    memberInfo: '',  // 搜索会员内容
    mcd_mid: '',  // 会员id
    mcdSearch: '',  // 搜索优惠价内容
    mcd_mgid: '',  // 会员优惠券id
    mcd_remark: '',  // 备注
  });
  const filters = ref([
    {
      filterType: "search",
      name: "keywords",
      value: "",
      placeholder: "搜索会员卡编号、姓名、手机号",
    },
    {
      filterType: "select",
      name: "type",
      value: "",
      placeholder: "请选择会员卡类型",
      options: [],
      val: "stt_id",
      lab: "stt_name",
      action: "typeChange",
    },
    {
      filterType: "select",
      name: "mcd_name",
      value: "",
      placeholder: "请选择二级类型",
      options: [],
      val: "name",
      lab: "name",
    },
    {
      filterType: "select",
      name: "status",
      value: "",
      placeholder: "请选择状态",
      options: [],
      val: "id",
      lab: "name",
    },
		{
      filterType: "date",
      type: "daterange",
			name: "start_time",
      name2: "end_time",
			value: [],
      rangeText: "-",
		},
  ]);
  const tableColumns = ref([  // 表格配置数据
    {
      prop: "mcd_sn",
      label: "会员卡ID",
      color: "--text-third-color",
    },
    {
      prop: "mcd_name",
      label: "名称",
    },
    {
      prop: "type_name",
      label: "类型",
    },
    {
      prop: "s_name",
      label: "园区",
    },
    {
      prop: "m_mobile",
      label: "联系方式",
    },
    {
      prop: "use_status_msg",
      label: "状态",
    },
    {
      prop: "enable_total_count",
      label: "总可用次数",
    },
    {
      prop: "enable_day_count",
      label: "当天可用次数",
    },
    {
      prop: "mcd_create_time",
      label: "出票日期",
    },
    {
      prop: "card_source_text",
      label: "来源",
    },
    {
      prop: "mcd_stime",
      label: "开始日期",
    },
    {
      prop: "mcd_etime",
      label: "结束日期",
    },
    {
      type: "operation",
      prop: "",
      label: "操作",
      minWidth: 200,
      bottons: [
        {
          name: "编辑",
          action: "edit",
          token: "m_vGShHwu3H35dOu0lbR6woJe5",
        },
        {
          name: "录入通行证",
          action: "registerBtn",
          type: "customRender",
          className: "theme-font-btn",
        },
      ],
    },
  ]);
  const rules = reactive({  // 表单内容必填验证
    mcd_name: [
      { required: true, message: "请输入会员卡名称", trigger: "blur", },
    ],
    mcd_sid: [
      { required: true, message: '请选择园区', trigger: 'change' }
    ],
    mcd_mcwid: [
      { required: true, message: '请选择来源', trigger: 'change' }
    ],
    mcd_stt_id: [
      { required: true, message: '请选择类型', trigger: 'change' }
    ],
    mcd_stt_sid: [
      { required: true, message: '请选择二级类型', trigger: 'change' }
    ],
    sellDate: [
      { required: true, message: "请选择会员卡日期", trigger: "change", },
    ],
    mcd_count: [
      { required: true, message: "请输入会员卡可用次数", trigger: "blur", },
    ],
    mcd_day_count: [
      { required: true, message: "请输入当天可用次数", trigger: "blur", },
    ],
    mcd_child_ticket_count: [
      { required: true, message: "请输入儿童数量", trigger: "blur", },
    ],
    mcd_audit_ticket_count: [
      { required: true, message: "请输入成人数量", trigger: "blur", },
    ],
  });
  const entryPassRef = ref(null);  // 录入通行证对象
  /**
   *
   * 监听权限
   *
   **/
  watch(
    () => menuTokens.value,
    (data) => {
      if (data.length) {
        authData.value = data;
      }
    },
    {
      deep: true,
      immediate: true,
    }
  );
  /**
   *
   * 新增、编辑按钮
   *
   **/
  const openDialog = (row) => {
    facialPhotos.value = [];
    srcList.value = [];
    if (row.mcd_id) {
      //编辑
      getSourceDatas(1);
      const data = JSON.parse(JSON.stringify(row));
      data.sellDate = [data.mcd_stime, data.mcd_etime];
      allPerson.value = { m_mobile: data.m_mobile, m_realname: data.m_realname };
      // allCoupon.value = {};
      let imgArrs = [];
      if (data.child_img.length > 0) {
        for (let i = 0; i < data.child_img.length; i++) {
          imgArrs.push({
            standard_url: data.child_img[i],
            key: data.mcd_child_img[i]
          })
          srcList.value.push(data.child_img[i]);
        }
        facialPhotos.value = imgArrs;
      }
      ruleForm.value = data;
      typesOfChange('2');
    } else {
      getSourceDatas(2);
      // 新增
      ruleForm.value = {
        mcd_name: '',
        mcd_sid: '',
        mcd_mcwid: '',
        mcd_stt_id: '',
        mcd_stt_sid: '',
        sellDate: [],
        mcd_count: '',
        mcd_day_count: '',
        mcd_child_ticket_count: '',
        mcd_audit_ticket_count: '',
        memberInfo: '',
        mcd_remark: '',
        mcdSearch: '',
        mcd_mid: '',
        mcd_mgid: '',
      };
      allPerson.value = { m_mobile: '', m_realname: '' };
      allCoupon.value = { id: '' };
      srcList.value = [];
    }
    // 显示上传
    showUpload.value = true;
    // 打开对话框
    dialogRef.value.show();
    store.dispatch("getQiniuData");
  };
  /**
   *
   * 新增、编辑确认提交按钮
   *
   **/
  const handleSure = () => {
    formRef.value.validate((valid) => {
      if (valid) {
        if (!ruleForm.value.mcd_mid) {
          ElMessage.error('请选择会员！');
          return false;
        }
        dialogRef.value.isLoading = true;
        const data = JSON.parse(JSON.stringify(ruleForm.value));
        // 参数
        let parmas = {
          mcd_name: data.mcd_name,
          mcd_sid: data.mcd_sid,
          mcd_mcwid: data.mcd_mcwid,
          mcd_stt_id: data.mcd_stt_id,
          mcd_stt_sid: data.mcd_stt_sid,
          mcd_stime: data.sellDate[0],
          mcd_etime: data.sellDate[1],
          mcd_count: data.mcd_count,
          mcd_day_count: data.mcd_day_count,
          mcd_child_ticket_count: data.mcd_child_ticket_count,
          mcd_audit_ticket_count: data.mcd_audit_ticket_count,
          mcd_remark: data.mcd_remark,
          mcd_mid: data.mcd_mid,
          mcd_mgid: data.mcd_mgid,
          child_img: [],
        };
        // 处理上传附件
        facialPhotos.value.forEach(item => {
          parmas.child_img.push(item.key);
        })
        let url = "";
        if (data.mcd_id) {
          // 编辑
          url = "updateMemberCard";
          parmas["mcd_id"] = data.mcd_id;
        } else {
          // 新增
          url = "addMemberCard";
        }
        // console.log("提交", url, parmas)
        VipApi[url](parmas).then((res) => {
          dialogRef.value.isLoading = false;
          const text = data.mcd_id ? "修改" : "新增";
          if (res.Code === 200) {
            ElMessage.success(`会员卡${text}成功！`);
            // 关闭对话框
            dialogRef.value.close();
            // 处理上传组件
            showUpload.value = false;
            // 重新获取列表数据
            tableRef.value.tableLoad();
          } else {
            let msg = res.Message ? res.Message : `会员${text}失败！`;
            ElMessage.error(msg);
          }
        });
      }
    });
  };
  const informationObj = ref({
    m_realname: "",
  });
  const isQuick = ref(false);
  const s_id = ref('');  // 快捷录入园区id
  /**
  *
  * 录入按钮
  *
  * */
  const registerBtn = (row) => {
    childrenInfo.value = [];
    adultInfo.value = [];
    fileList.value = [];
    s_id.value = '';
    if (row.mcd_id) {
      isQuick.value = false;
      entryPassRef.value.openDialog(row.mcd_id, 'member');
      // if (row.child_img.length > 0) {
      //   row.child_img.forEach(item => {
      //     fileList.value.push({
      //       file_url: item,
      //     })
      //   })
      // }
      // // 列表录入
      // mcd_id.value = row.mcd_id;
      // if (row.mcd_child_ticket_count >= 0) {
      //   for (var i = 0; i < +row.mcd_child_ticket_count; i++) {
      //     const arr = JSON.parse(JSON.stringify(informationObj.value));
      //     childrenInfo.value.push(arr);
      //   }
      // }
      // if (row.mcd_audit_ticket_count >= 0) {
      //   for (var i = 0; i < +row.mcd_audit_ticket_count; i++) {
      //     const arr = JSON.parse(JSON.stringify(informationObj.value));
      //     adultInfo.value.push(arr);
      //   }
      // }
    } else {
      // 快捷录入
      isQuick.value = true;
      childrenInfo.value.push(JSON.parse(JSON.stringify(informationObj.value)));
      adultInfo.value.push(JSON.parse(JSON.stringify(informationObj.value)));
      ticketDialog.value.show();
    }
  }
  /**
   * 
   * 录入通行证确定按钮
   * 
   * */
  const determine = (data) => {
    entryPassRef.value.dialogRef.isLoading = true;
    VipApi.cardReceive(data).then((res) => {
      entryPassRef.value.dialogRef.isLoading = false;
      if (res.Code === 200) {
        ElMessage.success(`录入成功！`);
        entryPassRef.value.closeDialog();
        tableRef.value.tableLoad();
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  /**
  *
  * 录入确定按钮
  *
  * */
  const confirm = () => {
    const parmas = {
      receive_record_child: [],  // 儿童
      receive_record_audit: [],  // 成人
    };
    let repeatArr = [];
    let url = "";
    if (isQuick.value) {
      // 如果是快捷录入
      if (s_id.value == '') {
        // 验证是否选择园区
        ElMessage.error('请选择园区');
        return false;
      }
      parmas.s_id = s_id.value;
      url = "cardReceiveIndie";
    } else {
      // 列表录入
      parmas.mcd_id = mcd_id.value;
      url = "cardReceive";
    }
    // 处理儿童信息
    childrenInfo.value.forEach(item => {
      if (item.paper_sn) {
        parmas.receive_record_child.push(item.paper_sn);
        repeatArr.push(item.paper_sn);
      }
    })
    // 处理成人信息
    adultInfo.value.forEach(item => {
      if (item.paper_sn) {
        parmas.receive_record_audit.push(item.paper_sn);
        repeatArr.push(item.paper_sn);
      }
    })
    if (duplicates(repeatArr).length > 0) {
      ElMessage.error('重复录入!');
      return false;
    }
    ticketDialog.value.isLoading = true;
    // console.log("确定按钮", parmas, childrenInfo.value, adultInfo.value)
    VipApi[url](parmas).then((res) => {
      ticketDialog.value.isLoading = false;
      if (res.Code === 200) {
        ElMessage.success(`录入成功！`);
        ticketDialog.value.close();
        tableRef.value.tableLoad();
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  /**
  *
  * 快捷录入判断是否重复
  *
  * */
  const childrenBlur = () => {
    let braceletArr = [];
    // 处理儿童信息
    childrenInfo.value.forEach(item => {
      if (item.paper_sn) {
        braceletArr.push(item.paper_sn);
      }
    })
    // 处理成人信息
    adultInfo.value.forEach(item => {
      if (item.paper_sn) {
        braceletArr.push(item.paper_sn);
      }
    })
    if (duplicates(braceletArr).length > 0) {
      ElMessage.error('重复录入!');
      return false;
    }
  }
  const handleSn = (data, index, index2) => {
    childrenBlur();
    if (data == 'children') {
      // 儿童
      if (index2 == childrenInfo.value.length) {
        // 如果当前光标在儿童最后一行，则光标跳成人第一行
        document.getElementById('adult0').focus();
      } else {
        document.getElementById(data + index2).focus();
      }
    } else {
      // 成人
      if (index2 !== adultInfo.value.length) {
        document.getElementById(data + index2).focus();
      }
    }
    // const visitorSnRef = document.getElementById(data + index); // 当前名字框
    // const nextVisitorSnRef = document.getElementById(data + index2); // 下一个名字框
  }
  /**
  *
  * 新增一行
  *
  * */
  const onAddLineBtn = (type) => {
    if (type == 'children') {
      // 增加儿童
      childrenInfo.value.push(JSON.parse(JSON.stringify(informationObj.value)));
    } else {
      // 增加成人
      adultInfo.value.push(JSON.parse(JSON.stringify(informationObj.value)));
    }
  }
  /**
  *
  * 获取优惠券选项
  *
  * */
  const getCouponDatas = () => {
    VipApi.getGiftTypeLists({}).then((res) => {
      if (res.Code === 200) {
        couponOptions.value = res.Data;
      } else {
        let msg = res.Message ? res.Message : "获取优惠券失败！";
        ElMessage.error(msg);
      }
    });
  }
  /**
  *
  * 获取园区选项
  *
  * */
  const getParkDatas = () => {
    BasicApi.getScenicByProject({}).then((res) => {
      if (res.Code === 200) {
        parkOptions.value = res.Data;
      } else {
        let msg = res.Message ? res.Message : "获取园区失败！";
        ElMessage.error(msg);
      }
    });
  }
  /**
  *
  * 获取类型选项
  *
  * */
  const getTypeDatas = () => {
    ScenicApi.ticketType({}).then((res) => {
      if (res.Code === 200) {
        typesOptions.value = res.Data;
      } else {
        let msg = res.Message ? res.Message : "获取类型失败！";
        ElMessage.error(msg);
      }
    });
  }
  /**
  *
  * 获取筛选条件类型选项
  *
  * */
  const getNewTicketType = () => {
    ScenicApi.getNewTicketType({}).then((res) => {
      if (res.Code === 200) {
        filters.value[1].options = res.Data;
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  /**
  *
  * 获取状态选项
  *
  * */
  const getStatusDatas = () => {
    ScenicApi.getMemberCardStatus({}).then((res) => {
      if (res.Code === 200) {
        filters.value[3].options = res.Data;
      } else {
        let msg = res.Message ? res.Message : "获取状态失败！";
        ElMessage.error(msg);
      }
    });
  }
  /**
  *
  * 获取来源选项
  *
  * */
  const getSourceDatas = (type) => {
    VipApi.getMemberCardWayLists({ type }).then((res) => {
      if (res.Code === 200) {
        sourceOptions.value = res.Data;
      } else {
        let msg = res.Message ? res.Message : "获取来源失败！";
        ElMessage.error(msg);
      }
    });
  }
  const isShowMemberDatas = ref(false);  // 是否显示搜索会员数据
  const allPerson = ref({ m_mobile: '', m_realname: '' });  // 选择会员数据
  /**
  *
  * 搜索会员信息
  *
  * */
  const querySearch = (queryString, cb) => {
    isShowMemberDatas.value = false;
    // 搜索内容为空不调接口
    if (!queryString) {
      cb([]);
      return false;
    };
    BasicApi.getMemberTicket({ keywords: queryString }).then((res) => {
      if (res.Code === 200) {
        let defaultDatas = [];
        isShowMemberDatas.value = true;
        if (res.Data) {
          cb(res.Data);
        } else {
          defaultDatas = [{ default: '无会员' }]
          cb(defaultDatas);
        };
      } else {
        let msg = res.Message ? res.Message : "获取会员信息失败！";
        ElMessage.error(msg);
      }
    });
  }
  /**
  *
  * 选择搜索会员
  *
  * */
  const autocomplete = ref(null);
  const handleSelect = (item) => {
    ruleForm.value.memberInfo = '';
    allPerson.value = item;
    ruleForm.value.mcd_mid = item.m_id;
    autocomplete.value.close();
  }
  /**
   * 
   * 注册会员
   * 
   * */
  const onRegisterBtn = () => {
    if (!checkStr(ruleForm.value.memberInfo, "phone")) {
      ElMessage.error(
        `注册会员手机号格式错误！`
      );
      return false;
    }
    VipApi.memberAdd({ m_mobile: ruleForm.value.memberInfo }).then((res) => {
      if (res.Code === 200) {
        handleSelect(res.Data);
      } else {
        let msg = res.Message ? res.Message : "会员注册失败！";
        ElMessage.error(msg);
      }
    });
  }
  const couponRefs = ref(null);
  const allCoupon = ref({ id: '' });
  /**
  *
  * 选择优惠券
  *
  * */
  const couponSearch = (queryString, cb) => {
    // 搜索内容为空不调接口
    if (!queryString) {
      cb([]);
      return false;
    }; cb([]);
    // BasicApi.getMemberTicket({ keywords: queryString }).then((res) => {
    //   if (res.Code === 200) {
    //     cb(res.Data);
    //   } else {
    //     let msg = res.Message ? res.Message : "获取优惠券信息失败！";
    //     ElMessage.error(msg);
    //   }
    // });
  }
  /**
  *
  * 选择优惠券
  *
  * */
  const couponSelect = (item) => {
    ruleForm.value.mcdSearch = '';
    allCoupon.value = item;
    ruleForm.value.mcd_mgid = item.id;
    couponRefs.value.close();
  }
  const faceLoading = ref(false);  // 是否加载
  const faceDialogRef = ref(null);  // 拍照上传对象
  const facialPhotos = ref([]);  // 已上传附件
  const currentCameraIndex = ref(null);
  const srcList = ref([]);  // 预览图片数据
  // 加载上传附件
  function uploadLoading(flag) {
    faceLoading.value = flag;
  }
  // 点击拍照录入
  function openFaceDialog(index) {
    currentCameraIndex.value = index;
    faceDialogRef.value.openDialog();
  }
  // 拍照录入确定按钮
  function handleCamera(data) {
    srcList.value.push(data.standard_url);
    facialPhotos.value.push(data);
    // visitorList.value[currentCameraIndex.value].img = [
    //   { file_url: data.standard_url },
    // ];
    // visitorList.value[currentCameraIndex.value].file_key = data.key;
    faceDialogRef.value.closeDialog();
  }
  /**
   * 
   * 图片上传
   * 
   * */
  const uploadFile = (obj) => {
    faceLoading.value = false;
    srcList.value.push(obj.standard_url);
    facialPhotos.value.push(obj);
  };
  /**
   * 
   * 图片删除
   * 
   * */
  const deleteFile = (obj, i) => {
    facialPhotos.value.splice(i, 1);
    srcList.value.splice(i, 1);
  };

  /**
   * 
   * 选择会员卡类型获取二级分类
   * 
   * */
  const typeChange = (item) => {
    filters.value[2].value = '';
    tableRef.value.tableLoad();
    if(item.value == '') {
      filters.value[2].options = [];
      return false;
    }
    ScenicApi.getSourceNameByType({ source: '2', type: item.value }).then((res) => {
      if (res.Code === 200) {
        filters.value[2].options = res.Data ? res.Data : [];
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  const secondaryTypeOption = ref([]);  // 二级类型选项
  /**
   * 
   * 选择门票类型获取二级类型
   * 
   * **/
  const typesOfChange = (type) => {
    if (type == '1') {
      ruleForm.value.mcd_stt_sid = '';
    }
    if (!ruleForm.value.mcd_stt_id) {
      secondaryTypeOption.value = [];
      return false;
    }
    ScenicApi.ticketType({ stt_pid: ruleForm.value.mcd_stt_id }).then((res) => {
      if (res.Code === 200) {
        secondaryTypeOption.value = res.Data ? res.Data : [];
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  onMounted(() => {
    tableRef.value.tableLoad();
    getCouponDatas();
    getTypeDatas();
    getStatusDatas();
    getParkDatas();
    getNewTicketType();
  });
</script>
<style lang="scss">
  .upload-box {
    margin-bottom: 20px;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .into-face {
    color: #33A8FF;
    cursor: pointer;
  }

  .el-autocomplete {
    width: 100%;
  }

  .person_content {
    background-color: #DDF1FF;
    color: #33A8FF;
    margin-top: 10px;
    line-height: 38px;
    padding: 0 10px;
    width: 200px;
  }

  .vip-container {
    font-family: "Source Han Sans CN";

    .content {
      padding: 15px 20px 20px;
    }

    .vip-dialog {
      .el-overlay-dialog {
        overflow: hidden;
      }

      .el-dialog {
        min-width: 600px;

        .el-dialog__body {
          padding: 30px;

          .el-divider--horizontal {
            margin: 10px 0;
          }
        }
      }
    }
  }

  .enter-dialog {
    .ticket-content {
      overflow-y: auto;
    }

    .enter-title {
      color: var(--text-color);
      font-size: 14px;
      margin: 10px 0;
    }

    .add-btn {
      text-align: center;
      width: 200px;
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;
      background-color: var(--btn-bg-color);
      color: var(--text-white-color);
      border: 1px solid var(--btn-bg-color);
    }

    .visitor-item {
      padding: 10px 0;
      position: relative;

      .visitor-form {
        flex: 1;
        display: flex;
        flex-wrap: wrap;

        .el-form-item__content {
          line-height: 20px;
        }

        .el-form-item__label {
          padding-right: 0;
          user-select: none;
        }

        .el-input__inner {
          padding-right: 10px;
        }

        .el-form-item {
          margin-right: 10px;
          padding: 10px 0;
          border: 2px dashed #eee;
          align-items: center;
          margin-bottom: 8px;

          .el-input__inner {
            height: 20px;
            line-height: 20px;
          }

          .el-form-item__error {
            top: 68%;
            left: 15px;
          }
        }

        .el-form-item__content .el-input__inner,
        .el-form-item__content .el-textarea__inner {
          background-color: transparent;
        }
      }
    }
  }
</style>